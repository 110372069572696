.swirl {
  opacity: 0.07;
  background-color: #e5e5f7;
  background-image: repeating-radial-gradient(
      circle at 0 0,
      transparent 0,
      #e5e5f7 10px
    ),
    repeating-linear-gradient(#444cf755, #444cf7);
}
